<template>
    <div>
        <div class="multi-ctrls">
            <a-select default-value="1-1" style="width: 200px" v-model="selectedFreq" @change="changeFreq">
                <a-select-opt-group>
                    <span slot="label">平台数据({{allSequencesLength}})</span>
                    <a-select-option value="1-1">
                        年度数据({{allSequencesTree[0]&&allSequencesTree[0].children.length?allSequencesTree[0].children.length:0}})
                    </a-select-option>
                    <a-select-option value="3-1">
                        季度数据({{allSequencesTree[1]&&allSequencesTree[1].children.length?allSequencesTree[1].children.length:0}})
                    </a-select-option>
                    <a-select-option value="4-1">
                        月度数据({{allSequencesTree[2]&&allSequencesTree[2].children.length?allSequencesTree[2].children.length:0}})
                    </a-select-option>
                </a-select-opt-group>
                <a-select-opt-group>
                    <span slot="label">我的数据{{allPersonalSequencesLength}}</span>
                    <a-select-option value="1-2">
                        年度数据({{allPersonalSequencesTree[0]&&allPersonalSequencesTree[0].children.length?allPersonalSequencesTree[0].children.length:0}})
                    </a-select-option>
                    <a-select-option value="3-2">
                        季度数据({{allPersonalSequencesTree[1]&&allPersonalSequencesTree[1].children.length?allPersonalSequencesTree[1].children.length:0}})
                    </a-select-option>
                    <a-select-option value="4-2">
                        月度数据({{allPersonalSequencesTree[2]&&allPersonalSequencesTree[2].children.length?allPersonalSequencesTree[2].children.length:0}})
                    </a-select-option>
                </a-select-opt-group>
            </a-select>
            <a-button type="danger" :disabled="selectedRowKeys.length===0" @click="mutilDel">
                删除
            </a-button>
        </div>
        <a-table 
            :columns="columns" 
            :data-source="list" 
            :rowKey="record=>record.cube_ID" 
            :pagination="false" 
            :bordered="false"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            >
            <template slot="cube_NAME" slot-scope="text,record">
                <div v-if="record.cube_ID!==editKey">
                    <router-link v-if="dataType===1" :to="{path:'/cloud_index',query:{reportId:record.cube_ID,freqId:record.freq_ID}}">{{text}}</router-link>
                    <router-link v-else :to="{path:'/cloud_index',query:{uploadId:record.cube_ID,freqId:record.freq_ID}}">{{text}}</router-link>
                </div>
                
                <div class="cell-edit" v-else>
                    <a-input v-model="record.cube_NAME" style="width:150px" />
                    <a-icon type="check-circle" theme="filled" title="保存" @click="save(record)" />
                    <a-icon type="close-circle" theme="filled" title="取消" @click="editKey=null;getFiles();" />
                </div>
            </template>
            <template slot="range" slot-scope="text,record">
                {{record.start_DATE+'~'+record.last_DATE}}
            </template>
            <template slot="freq_ID" slot-scope="text">
                <span v-if="text===1">年度</span>
                <span v-if="text===3">季度</span>
                <span v-if="text===4">月度</span>
            </template>
            <template slot="actions" slot-scope="text,record">
                <a-button type="link" @click="editIndicator(record)">编辑指标</a-button>
                <a-button type="link" @click="edit(record)">重命名</a-button>
                <a-button type="link" @click="del(record)">删除</a-button>
            </template>
        </a-table>
    </div>
</template>

<script>
import { messageTips, setSessionItem } from '../../until/tools';
const columns = [
	{
		title: '序列集',
		dataIndex: 'cube_NAME',
		key: 'cube_NAME',
		scopedSlots: { customRender: 'cube_NAME' },
	},
	{
		title: '时间范围',
		dataIndex: 'range',
		key: 'range',
		scopedSlots: { customRender: 'range' },
        align:'center'
    },
    {
		title: '时间频度',
		dataIndex: 'freq_ID',
		key: 'freq_ID',
		scopedSlots: { customRender: 'freq_ID' },
        align:'center'
    },
    {
		title: '修改时间',
		dataIndex: 'lstmod_DATE',
		key: 'lstmod_DATE',
        align:'center'
    },
    {
		title: '操作',
		dataIndex: 'actions',
		key: 'actions',
        scopedSlots: { customRender: 'actions' },
        align:'center'
	},
];
export default {
    name:'Collections',
    data() {
        return {
            columns,
            list:[],
            editKey:null,
            selectedRowKeys:[],
            allSequencesLength:0,
            allSequencesTree:[],
            allPersonalSequencesLength:0,
            allPersonalSequencesTree:[],
            selectedFreq:this.$route.query.freq?this.$route.query.freq:'1-1',
        }
    },
    computed:{
        dataType() {
            let arr = this.selectedFreq.split('-');
            if(arr[1]) {
                return parseInt(arr[1]);
            }
            return null;
        }
    },
    created() {
        this.getFiles();
        this.getAllSequence();
		this.getAllPersonalSequence();
    },
    watch:{
        selectedFreq() {
            this.getFiles();
        }
    },
    methods:{
        changeFreq(value) {
            this.$router.push({path:'/user_series',query:{freq:value}})
        },
        getFiles() {
            let arr = this.selectedFreq.split('-');
            if(arr[1]==='1') {
                this.$http.user_getSeries({freqId:arr[0]}).then(res=>{
                    if(res) {
                        this.list = res;
                    }
                })
            } else {
                this.$http.user_getMydata({freqId:arr[0]}).then(res=>{
                    if(res.myData) {
                        this.list = res.myData;
                    }
                })
            }           
        },
        visitThis(item) {
            var contents = JSON.parse(item.contents);
            if (contents.metaFixed.length > 0 && contents.metaFixed[0] !== '') {
                contents.dimensionVOLst.forEach((v)=> {
                    if (contents.metaFixed.indexOf(v.codeName) !== -1) {
                        v.codes = [v.codes[0]];
                    }
                });
            }
            var cubeId = contents.cubeId;
            delete contents.cubeId;            
            setSessionItem('dims',JSON.stringify(contents));
            this.$router.push({
                path:'/datas_data',
                query:{cubeIid:cubeId}
            });
        },
        edit(item) {
            this.editKey = item.cube_ID;
        },
        save(item) {
            this.$http.renameReport({cubeId:item.cube_ID,name:item.cube_NAME}).then(res=>{
                if(res) {
                    if(res.success) {
                        this.editKey = null;
                    } else {
                        messageTips(res.message);
                    }
                }
            });
        },
        editIndicator(item) {
            this.$router.push({path:'/user_series_indicator',query:{cubeId:item.cube_ID,cubeName:item.cube_NAME,dataType:this.dataType}});
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        del(item) {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    let params = {
                        cubeId:item.cube_ID
                    }
                    that.confirmDel(params);
                },
                onCancel() {},
            });  
        },
        mutilDel() {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    that.selectedRowKeys.forEach(item=>{
                        let params = {
                            parentId:0,
                            cubeId:item
                        }
                        that.confirmDel(params);
                    });                    
                },
                onCancel() {},
            });  
        },
        confirmDel(params) {
            if(this.dataType===1) {
                this.$http.delReport(params).then(res=>{
                    if(res) {
                        if(res.success) {
                            this.getFiles();
                            this.getAllSequence();
                            this.getAllPersonalSequence();
                        } else {
                            messageTips(res.message,1);
                        }
                    }
                });
            } else {
                this.$http.delUploadReport(params).then(res=>{
                    if(res) {
                        if(res.success) {
                            this.getFiles();
                            this.getAllSequence();
                            this.getAllPersonalSequence();
                        } else {
                            messageTips(res.message,1);
                        }
                    }
                });
            }
            
        },


        getAllSequence() {
            this.$http.user_getSeries().then((res)=> {
                if(res) {
                    this.allSequencesLength = res.length;
                    this.allSequencesTree = this.dealReport(res);
                }
            });
        },

        getAllPersonalSequence() {
            this.$http.user_getMydata().then((res)=> {
                if(res) {
                    this.allPersonalSequencesLength = res.myData.length;
                    this.allPersonalSequencesTree = this.dealReport(res.myData);
                }
            });
        },
        dealReport(data) {
            var result = [{title:'年度时序数据',children:[]},{title:'季度时序数据',children:[]},{title:'月度时序数据',children:[]}];
            data.forEach(v=>{
                if(v.freq_ID===1) {
                    result[0].children.push(v);
                } else if(v.freq_ID===3) {
                    result[1].children.push(v);
                } else if(v.freq_ID===4) {
                    result[2].children.push(v);
                }
            });
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/_variables.scss';
    .cell-edit {
        .anticon {
            margin:0 0 0 10px;
            font-size: 20px;
        }
        .anticon-check-circle {
            color:$primary-color;
        }
        .anticon-close-circle {
            color:$warning-color;
        }
    }
    .multi-ctrls {
        padding:20px 0;
        button {
            float:right;
        }
    }
</style>