<template>
    <div>
        <div class="multi-ctrls">
            <span class="sheetName">
                序列集：{{seriesName}}
            </span>
            <a-button type="danger" :disabled="selectedRowKeys.length===0" @click="mutilDel">
                删除
            </a-button>
        </div>
        <a-table 
            :columns="columns" 
            :data-source="list" 
            :rowKey="record=>record.indicator_CODE" 
            :pagination="false" 
            :bordered="false"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            >
            <template slot="indicator_NAME" slot-scope="text,record">
                <div href="javascript:;" v-if="record.indicator_CODE!==editKey">{{text}}</div>
                <div class="cell-edit" v-else>
                    <a-input v-model="record.indicator_NAME" style="width:150px" />
                    <a-icon type="check-circle" theme="filled" title="保存" @click="save(record)" />
                    <a-icon type="close-circle" theme="filled" title="取消" @click="editKey=null;getFiles();" />
                </div>
            </template>
            <template slot="actions" slot-scope="text,record">
                <a-button type="link" @click="edit(record)">重命名</a-button>
                <a-button type="link" @click="del(record)">删除</a-button>
            </template>
        </a-table>
    </div>
</template>

<script>
import { messageTips } from '../../until/tools';
const columns = [
    {
        title: '序列名称',
        dataIndex: 'indicator_NAME',
        key: 'indicator_NAME',
        scopedSlots: { customRender: 'indicator_NAME' },
    },
    {
        title: '来源子库',
        dataIndex: 'dim_CUBE',
        key: 'dim_CUBE',
    },
    {
        title: '修改时间',
        dataIndex: 'lstmod_DATE',
        key: 'lstmod_DATE',
        align:'center'
    },
    {
        title: '操作',
        dataIndex: 'actions',
        key: 'actions',
        scopedSlots: { customRender: 'actions' },
        align:'center'
    },
];
export default {
    name:'Collections',
    data() {
        return {
            columns,
            list:[],
            editKey:null,
            selectedRowKeys:[],
            seriesName:this.$route.query.cubeName,
            dimType:null
        }
    },
    computed:{
        dataType() {
            let type = this.$route.query.dataType;
            return parseInt(type);
        }
    },
    created() {
        this.getFiles();
    },
    methods:{
        getFiles() {
            if(this.dataType===1) {
                this.$http.getReportIndicator({reportId:this.$route.query.cubeId}).then(res=>{
                    if(res&&res.list) {
                        this.list = res.list;
                        this.dimType = res.dimensionId;
                    }
                });
            } else {
                this.$http.getUploadIndicator({cubeId:this.$route.query.cubeId}).then(res=>{
                    if(res&&res.list) {
                        this.list = res.list;
                        this.dimType = res.dimensionId;
                    }
                });
            }            
        },
        edit(item) {
            this.editKey = item.indicator_CODE;
        },
        save(item) {
            if(this.dataType===1) {
                this.$http.renameIndicator({indicatorCode:item.indicator_CODE,name:item.indicator_NAME,cubeId:item.cube_ID}).then(res=>{
                    if(res) {
                        if(res.success) {
                            this.editKey = null;
                        } else {
                            messageTips(res.message);
                        }
                    }
                });
            } else {                
                this.$http.renameUploadIndicator({indicatorCode:item.indicator_CODE,name:item.indicator_NAME,cubeId:item.cube_ID}).then(res=>{
                    if(res) {
                        if(res.success) {
                            this.editKey = null;
                        } else {
                            messageTips(res.message);
                        }
                    }
                });
            }
            
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        del(item) {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    let params = {
                        cubeId:item.cube_ID,
                        indicatorCode:item.indicator_CODE,
                    }
                    that.confirmDel(params);
                },
                onCancel() {},
            });  
        },
        mutilDel() {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    let params = {
                        cubeId:that.$route.query.cubeId,
                        indicatorCode:that.selectedRowKeys.join(','),
                    }
                    that.confirmDel(params);                    
                },
                onCancel() {},
            });  
        },
        confirmDel(params) {
            if(this.dataType===1) {
                this.$http.user_del_series_indicator(params).then(res=>{
                    if(res) {
                        if(res.success) {
                            this.getFiles();
                        } else {
                            messageTips(res.message,1);
                        }
                    }
                });

            } else {
                this.$http.user_del_upload_indicator(params).then(res=>{
                    if(res) {
                        if(res.success) {
                            this.getFiles();
                        } else {
                            messageTips(res.message,1);
                        }
                    }
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/_variables.scss';
    .cell-edit {
        .anticon {
            margin:0 0 0 10px;
            font-size: 20px;
        }
        .anticon-check-circle {
            color:$primary-color;
        }
        .anticon-close-circle {
            color:$warning-color;
        }
    }
    .multi-ctrls {
        padding:20px 0;
        .sheetName {
            font-size: 16px;
            font-weight: bold;
        }
        button {
            float:right;
        }
    }
</style>